import React, { useEffect } from 'react';

import classes from './CreateConnectionStepFive.module.css';
import SetCronJob from '../../../../../../shared-front/SetCronJob/SetCronJob';
import { useCronReducer } from '../../../../../../shared-front/SetCronJob/useCronReducer';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import {
  selectorActiveSourceType,
  selectorNewConnectionGeneralSettings,
  selectorNewConnectionPreviewSchedule,
  selectorNewConnectionScanSchedule,
  selectorNewConnectionSelectedAssets,
  setConnectionPreviewSchedule,
  setConnectionScanSchedule,
} from '../../../../../../store/createNewConnectionSlice';
import CollapseBlock from '../../../../../../Components/CollapseBlock/CollapseBlock';
import { defaultCronValue } from '../../../../../../utils/constants';

const CreateConnectionStepFive: React.FC = () => {
  const dispatch = useAppDispatch();
  const connectionScanSchedule = useAppSelector(selectorNewConnectionScanSchedule);
  const connectionPreviewSchedule = useAppSelector(selectorNewConnectionPreviewSchedule);
  const selectedAssets = useAppSelector(selectorNewConnectionSelectedAssets);
  const activeSourceType = useAppSelector(selectorActiveSourceType);
  const connectionGeneralSettings = useAppSelector(selectorNewConnectionGeneralSettings);

  const [cronValuesForScan, dispatchCronValuesForScan] = useCronReducer(connectionScanSchedule ?? defaultCronValue);
  const [cronValuesForPreview, dispatchCronValuesForPreview] = useCronReducer(
    connectionPreviewSchedule ?? defaultCronValue
  );

  const assetsPreviewLimitsValue = selectedAssets?.map((asset) => {
    return (
      (asset?.settings?.find((setting) => setting.key === 'limits_for_preview')?.value as string | null | undefined) ??
      null
    );
  });

  const generalSettingsPreviewLimitsValue = connectionGeneralSettings?.find(
    (setting) => setting.key === 'limits_for_preview'
  )?.value;

  const isDb = activeSourceType?.card_view === 'db';

  const hasPreview = isDb ? assetsPreviewLimitsValue?.find((val) => val !== null) : !!generalSettingsPreviewLimitsValue;

  useEffect(() => {
    dispatch(setConnectionScanSchedule(cronValuesForScan.cronValue));
  }, [dispatch, cronValuesForScan.cronValue]);

  useEffect(() => {
    hasPreview && dispatch(setConnectionPreviewSchedule(cronValuesForPreview.cronValue));
  }, [dispatch, cronValuesForPreview.cronValue, hasPreview]);

  return (
    <div className={classes.container}>
      <CollapseBlock
        title={'Расписание сканирования'}
        isOpen={true}
        isShortContainer={true}
        isShortChildrenContainer={true}
      >
        <SetCronJob cronValues={cronValuesForScan} dispatchCronValues={dispatchCronValuesForScan} />
      </CollapseBlock>
      {hasPreview && (
        <CollapseBlock
          title={'Расписание сканирования предпросмотра'}
          isOpen={true}
          isShortContainer={true}
          isShortChildrenContainer={true}
        >
          <SetCronJob cronValues={cronValuesForPreview} dispatchCronValues={dispatchCronValuesForPreview} />
        </CollapseBlock>
      )}
    </div>
  );
};

export default CreateConnectionStepFive;
