import React from 'react';
import 'normalize.css';
import './index.css';
import App from './Components/App/App';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { displayClientVersion } from './utils/displayClientVersion';

const container = document.getElementById('root')!;
const root = createRoot(container);
const persistor = persistStore(store);

export const CLIENT_VERSION = '1.1.154';

displayClientVersion(CLIENT_VERSION);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
